import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Popover, PopoverTrigger } from '@mindpal-co/mindpal-ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import type { FC } from 'react';

import { useDeleteExternalCandidates } from '@/hooks/useDeleteExternalCandidates';
import { queries } from '@/queries';
import { apiRoutes } from '@/services/config';
import type { DownloadCVFormat } from '@/services/cvGenerator';
import type { ExternalCandidateList } from '@/services/externalCandidates';
import { postCvsToJobMatchingOffer } from '@/services/jobmatching';
import { postReasoningSearch } from '@/services/reasoningSearch';
import { useDashboard } from '@/stores/useDashboardStore';

import Button from '../Button';
import Checkbox from '../Form/Fields/Checkbox';
import AddJobmatchingOfferDialog from '../JobMatching/AddJobmatchingOfferDialog';
import Text from '../Text';
import { PopoverContent } from '../ui/popover';
import AddCandidateToCollection from './AddCandidateToCollectionDialog';

type Props = {
  selectedContexts: ExternalCandidateList[];
  allRowsSelcted?: boolean;
  toggleAllRowsSelected?: () => void;
  onClear: () => void;
  showJobMatchingButton?: boolean;
};
const SelectModeControls: FC<Props> = ({
  allRowsSelcted,
  toggleAllRowsSelected,
  selectedContexts,
  onClear,
  showJobMatchingButton = true,
}) => {
  const router = useRouter();
  const { mutate: deleteContexts } = useDeleteExternalCandidates();
  const { searchType, search } = useDashboard();

  const queryClient = useQueryClient();

  const { data: reasoningSearchPostResult } = useQuery({
    queryFn: () => postReasoningSearch(search),
    queryKey: ['reasoningSearchPost', search],
    enabled: searchType === 'advanced' && search.length > 0,
    staleTime: Infinity,
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['jobmatching', 'attachCvsToOffer'],
    mutationFn: postCvsToJobMatchingOffer,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.jobMatching.detail(reasoningSearchPostResult!.id)
          .queryKey,
      });
      router.push(`/dashboard/job-matching/${reasoningSearchPostResult!.id}`);
    },
  });

  const selectedCVContextsIds = selectedContexts.flatMap(({ cvContexts }) =>
    cvContexts.map(({ id }) => id)
  );

  const downloadZipUrl = (type?: DownloadCVFormat) => {
    window.open(
      `${apiRoutes.cvGenerator.context.downloadZip}?${QueryString.stringify(
        { cv: selectedCVContextsIds, type },
        { arrayFormat: 'repeat' }
      )}`
    );

    onClear();
  };

  return (
    <div className="col-span-full flex w-fit items-center gap-4 rounded-lg border border-transparent bg-neutral-1000 px-3 py-1">
      <div className="flex items-center gap-2">
        {toggleAllRowsSelected && typeof allRowsSelcted === 'boolean' && (
          <Checkbox
            {...{
              variant: 'transparent',
              className: '!p-0',
              name: 'headercheck',
              checked: allRowsSelcted,
              onChange: () => toggleAllRowsSelected(),
            }}
          />
        )}
        <Text
          variant="body-small"
          className="min-w-5 font-semibold"
        >{`${selectedContexts.length}`}</Text>
      </div>
      <AnimatePresence>
        {selectedContexts.length > 0 && (
          <motion.div
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: 395, opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            className="flex items-center gap-4 overflow-hidden"
          >
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  disabled={
                    selectedContexts.length <= 0 ||
                    selectedCVContextsIds.length <= 0
                  }
                  variant="unstyled"
                  size={null}
                  prefixIcon="material-symbols:download"
                  className="ml-4 rounded-none border-l pl-4"
                >
                  Report
                </Button>
              </PopoverTrigger>

              <PopoverContent className="flex w-fit flex-col gap-2 rounded-lg bg-neutral-700 p-2">
                <Button
                  variant="unstyled"
                  className="w-full  !justify-start self-start "
                  size={null}
                  onClick={() => downloadZipUrl('PDF')}
                >
                  Save as .PDF
                </Button>
                <Button
                  className="w-full !justify-start self-start"
                  variant="unstyled"
                  size={null}
                  onClick={() => downloadZipUrl('DOCX')}
                >
                  Save as .docx
                </Button>
              </PopoverContent>
            </Popover>
            <Button
              disabled={
                selectedContexts.length <= 0 ||
                selectedCVContextsIds.length <= 0
              }
              variant="unstyled"
              size={null}
              prefixIcon={faEdit}
              onClick={() => {
                router.push({
                  pathname: `/dashboard/generator/${selectedCVContextsIds?.[0]}`,
                  query: {
                    tabs: selectedCVContextsIds?.slice(1),
                  },
                });
              }}
            >
              Edit
            </Button>
            <Button
              disabled={selectedContexts.length <= 0}
              onClick={() =>
                deleteContexts(selectedContexts.map(({ id }) => id))
              }
              variant="unstyled"
              confirm={`Are you sure you want to delete selected resumes: ${selectedContexts
                .map(({ name }) => name)
                .join(', ')} `}
              size={null}
              prefixIcon={faTrash}
            >
              Delete
            </Button>
            <AddCandidateToCollection
              candidatesId={selectedContexts.map(({ id }) => id)}
              dialogTriggerProps={{ asChild: true }}
            >
              <Button
                variant="unstyled"
                className="whitespace-nowrap"
                size={null}
                prefixIcon={faPlus}
              >
                Add to collection
              </Button>
            </AddCandidateToCollection>
          </motion.div>
        )}
      </AnimatePresence>

      {showJobMatchingButton && (
        <>
          {searchType === 'simple' ? (
            <AddJobmatchingOfferDialog
              dialogTriggerProps={{ asChild: true }}
              externalCandidates={selectedContexts.map(({ id }) => id)}
              onSuccess={(response) =>
                router.push({
                  pathname: '/dashboard/job-matching/[id]',
                  query: { id: response.id },
                })
              }
            >
              <Button
                disabled={selectedContexts.length <= 0}
                className="!py-1"
                variant="tertiary"
                prefixIcon="majesticons:suitcase"
              >
                Job Matching
              </Button>
            </AddJobmatchingOfferDialog>
          ) : (
            <Button
              isLoading={isLoading}
              disabled={
                selectedContexts.length <= 0 || !reasoningSearchPostResult
              }
              onClick={() => {
                mutate({
                  cvIds: selectedContexts.map(({ id }) => id),
                  offerId: reasoningSearchPostResult!.id,
                });
              }}
              className="!py-1"
              variant="tertiary"
              prefixIcon="majesticons:suitcase"
            >
              Job Matching
            </Button>
          )}

          <div className="relative flex h-3 w-3 items-center justify-center">
            <div className="absolute h-full w-full animate-ping rounded-full bg-primary-700" />
            <div className=" absolute h-full  w-full rounded-full bg-primary-700" />
          </div>
        </>
      )}
    </div>
  );
};

export default SelectModeControls;
