import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import type { ButtonHTMLAttributes, ForwardedRef } from 'react';
import { forwardRef, useState } from 'react';

type Props = {
  icon: IconDefinition | string;
  iconClassName?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;
const ButtonWithLabelOnHover = (
  { children, icon, className, iconClassName, ...props }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const [hover, setHover] = useState(false);
  const variants = {
    visible: { width: 'fit-content' },
    hidden: { width: 0 },
  };
  return (
    <button
      type="button"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classNames(
        'flex w-fit group items-center gap-1 rounded-xl py-1 transition-all disabled:cursor-not-allowed disabled:opacity-50 [&:not(:disabled):hover]:bg-white [&:not(:disabled):hover]:px-2 [&:not(:disabled):hover]:text-neutral-1000',
        className
      )}
      {...props}
      ref={ref}
    >
      {typeof icon === 'string' ? (
        <Icon className={iconClassName} icon={icon} />
      ) : (
        <FontAwesomeIcon className={iconClassName} icon={icon} />
      )}

      <motion.span
        variants={variants}
        initial={false}
        className="overflow-hidden whitespace-nowrap text-caption"
        animate={hover && !props.disabled ? 'visible' : 'hidden'}
      >
        {children}
      </motion.span>
    </button>
  );
};

export default forwardRef(ButtonWithLabelOnHover);
